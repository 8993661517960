<!-- Start Woman Banner Area -->
<div class="uk-woman-banner woman-main-banner-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item uk-flex-middle">
                <div class="woman-banner-content">
                    <span>Welcome to CodeRyze </span>
                    <h1>Empower Your Future with IT Skills</h1>
                    <p>Learn today’s most in-demand digital skills and embark on a new career path. Our courses are
                        crafted to give you a deep understanding of the latest technologies and industry trends.</p>

                    <ul class="banner-btn-list">
                        <li>
                            <a routerLink="/contact" class="uk-button uk-button-default">Get Started</a>
                        </li>
                        <li>
                            <a routerLink="/about" class="uk-button uk-button-default">About Us</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="uk-item">
                <div class="woman-banner-image">
                    <img src="assets/img/home-seven/banner/wonan.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <ul class="woman-banner-social">
        <li>
            <span>Say Hello</span>
        </li>

        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li> -->
        <li><a href="https://www.linkedin.com/company/coderyze" target="_blank"><i class="flaticon-linkedin"></i></a>
        </li>
    </ul>
</div>
<!-- End Woman Banner Area -->

<!-- Start Creative About Area -->
<div class="uk-creative-about creative-about-area ptb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item">
                <div class="creative-about-left-content">
                    <div class="big-text">About Us</div>
                    <span>About Us</span>
                    <h3>Delivering IT Excellence Everyday</h3>
                    <p>CodeRyze is an innovative recruiting firm providing top-tier technical talent to the industry. We
                        have grown from a small scale operation to recognized provider of IT services for recruiting and
                        staffing industry experts, we are passionate about our mission to connect the greatest tech jobs
                        with the most talented professionals.</p>
                    <p>With extensive knowledge of technical, IT and product management across multiple industries and
                        verticals, we have delivered into creating solutions as well.</p>
                    <div class="about-left-image">
                        <img src="assets/img/home-seven/about/about1.jpg" alt="image">

                        <div class="content">
                            <h4>20</h4>
                            <b>Years Experience in Development</b>
                        </div>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-about-right-content">
                    <div class="about-right-image">
                        <img src="assets/img/home-seven/about/about2.jpg" alt="image">

                        <div class="content">
                            <h4>100%</h4>
                            <b>Job Guarantee</b>
                        </div>
                    </div>
                    <h3>We are an employee-owned management consulting</h3>
                    <p>CodeRyze provides innovative online training and development with career support programs for
                        students looking to start or advance their professions in a variety of technological and
                        business domain fields. We specialize in providing students practical real world knowledge and
                        skills that are required for success in the modern Information Technology industry. Our
                        experienced professionals take inspiration from CodeRyze,” the Japanese God of wisdom, and their
                        expertise spans a diverse range of techno functional spaces.</p>
                    <div class="about-right-btn">
                        <a routerLink="/about" class="uk-button uk-button-default">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative About Area -->

<!-- Start Creative Services Area -->
<div class="uk-creative-services creative-services-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">What We Do</div>
            <span>OUR SERVICES</span>
            <h2>Best Services for Your Business</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number">01</div>
                    <h3>
                        <a routerLink="/interview-pre">Interview Preparation for job</a>
                    </h3>
                    <p>How to Prepare for a Job Interview So You’ll Convince Them You’re the Right Hire. Here you can
                        work on in your practice interviews.</p>
                    <a routerLink="/interview-pre" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number two">02</div>
                    <h3>
                        <a routerLink="/sale-marketing">Sales and Marketing</a>
                    </h3>
                    <p>Sales and marketing are two business functions within an organization -- they both impact lead
                        generation and revenue.</p>
                    <a routerLink="/sale-marketing" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number three">03</div>
                    <h3>
                        <a routerLink="/job-assistance">Assistance for getting job</a>
                    </h3>
                    <p>100% Job Assistance. Assessing the Various Job Options Open to You. Get Professional,
                        Personalized Job Guidance.</p>
                    <a routerLink="/job-assistance" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number four">04</div>
                    <h3>
                        <a routerLink="/software-developer">Software Development</a>
                    </h3>
                    <p>We offer custom software development services specifically designed to meet business goals at
                        reduced costs.</p>
                    <a routerLink="/software-developer" class="services-btn">Read More <i
                            class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number five">05</div>
                    <h3>
                        <a routerLink="/ui-ux-design">UX/UI Design & Ideation</a>
                    </h3>
                    <p>Engross more customers by leveraging a well-defined design thinking approach and UI/UX strategies
                        helpful...</p>
                    <a routerLink="/ui-ux-design" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-card">
                    <div class="number six">06</div>
                    <h3>
                        <a routerLink="/web-development">Web Development</a>
                    </h3>
                    <p>We offer a variety of web-based software development services from creating mobile web
                        development solutions....</p>
                    <a routerLink="/web-development" class="services-btn">Read More <i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Services Area -->

<!-- Start Creative Projects Area -->
<!-- <div class="uk-creative-projects creative-projects-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Projects</div>
            <span>OUR COMPLETED PROJECTS</span>
            <h2>Take A Look At All The Projects That We Have Completed For The Client</h2>
        </div>
    </div>
    
    <div class="uk-container-expand">
        <div class="creative-projects-slides owl-carousel owl-theme">
            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Creative Projects Area -->

<!-- Start Creative Reviews Area -->
<div class="uk-creative-reviews creative-reviews-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Reviews</div>
            <span>Testimonials</span>
            <h2>What Client Says About Us</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-reviews-img">
                    <img src="assets/img/home-seven/reviews.jpg" alt="image">

                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-reviews-slides owl-carousel owl-theme">
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>"Reliable and Responsive Service CodeRyze Technologies has been our go- to partner for
                            sourcing software engineers.Their ability to quickly understand our requirements and provide
                            candidates who fit seamlessly into our projects is commendable.Their consultants are not
                            only skilled but also proactive in resolving any issues that arise.We appreciate their
                            flexibility and dedication to meeting our staffing needs efficiently. CodeRyze Technologies
                            has significantly contributed to our project successes, and we look forward to continuing
                            our partnership."</p>

                        <div class="client">
                            <!-- <h3>Google</h3> -->
                            <span>Google</span>
                        </div>
                    </div>

                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>"A Game-Changer for Staffing Needs CodeRyze Technologies has been instrumental in our
                            company's growth by consistently delivering exceptional software engineers. Their team's
                            meticulous screening process ensures that every candidate not only meets but exceeds our
                            expectations. Their responsiveness and proactive communication make them a pleasure to work
                            with. Highly recommend CodeRyze Technologies for any company looking to augment their
                            technical team with top-notch talent."</p>

                        <div class="client">
                            <!-- <h3>Jason Statham</h3> -->
                            <span>Nvidia</span>
                        </div>
                    </div>
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>"Seamless Integration and Exceptional Support CodeRyze Technologies has been instrumental in
                            filling critical software engineering roles within our organization. Their team understands
                            our needs deeply and consistently provides candidates who exceed our technical and cultural
                            expectations. Their consultants are responsive and proactive, ensuring that our projects
                            stay on track. CodeRyze Technologies dedication to quality and their ability to adapt to our
                            evolving requirements make them a trusted partner in our staffing strategy. We're grateful
                            for their contributions to our success and would recommend CodeRyze Technologies without
                            hesitation."</p>

                        <div class="client">
                            <!-- <h3>Jason Statham</h3> -->
                            <span>Lockheed Martin</span>
                        </div>
                    </div>
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>"Exceptional Engineering Expertise CodeRyze Technologies has consistently delivered
                            outstanding software engineers who have become integral parts of our development teams.
                            Their consultants bring not only technical proficiency but also a collaborative spirit that
                            aligns perfectly with our company culture. Their commitment to quality and their thorough
                            vetting process ensure that we receive candidates who are well-suited to our specific
                            project requirements. Working with Innovative Talent Partners has been a true partnership,
                            and we highly recommend their services to any company seeking top engineering talent."</p>

                        <div class="client">
                            <!-- <h3>Jason Statham</h3> -->
                            <span>Wells Fargo</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Reviews Area -->

<!-- Start Creative Partner Area -->
<div class="uk-creative-partner creative-partner-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Clients</div>
            <span>Valuable Clients</span>
            <h2>See Our Industry Valuable Clients</h2>
        </div>

        <div class="creative-partner-slides owl-carousel owl-theme">
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner5.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner7.png" alt="image">
                </a>
            </div>
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner8.png" alt="image">
                </a>
            </div>
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner9.png" alt="image">
                </a>
            </div>
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner10.png" alt="image">
                </a>
            </div>
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner11.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Partner Area -->

<!-- Start Creative Pricing Area -->
<!-- <section class="uk-creative-pricing creative-pricing-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Prices</div>
            <span>OUR PRICES</span>
            <h2>Our Flexible Pricing Plan</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Starter Plan</h3>
                    </div>

                    <div class="price">
                        $49.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>

                    <div class="price">
                        $59.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $99.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Creative Pricing Area -->

<!-- Start Creative Team Area -->
<section class="uk-creative-team creative-team-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Our Team</div>
            <span>MEET OUR EXPERTS</span>
            <h2>Meet Our Experts Team Member</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image">
                        <img src="assets/img/home-seven/team/team1.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://in.linkedin.com/in/amansingh-java?original_referer=https%3A%2F%2Fwww.google.com%2F"
                                    target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Aman Singh</h3>
                        <span>Java Developer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image style-two">
                        <img src="assets/img/home-seven/team/team2.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://www.linkedin.com/in/-conecthere/" target="_blank"><i
                                        class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Aman Yadav</h3>
                        <span>Delivery Manager </span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image">
                        <img src="assets/img/home-seven/team/team3.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://in.linkedin.com/in/ravi-gautam-9304a8218" target="_blank"><i
                                        class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Ravi Gautam</h3>
                        <span>Front-End Developer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image style-two">
                        <img src="assets/img/home-seven/team/team4.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://www.linkedin.com/in/harsh-tomar-connect/" target="_blank"><i
                                        class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Harsh Tomar</h3>
                        <span>Sr. Bench Sales Recruiter</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Team Area -->

<!-- Start Creative News Area -->
<section class="uk-creative-news creative-news-area pt-100 pb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Blog</div>
            <span>Our Blog</span>
            <h2>Read Our Blog</h2>
        </div>

        <div class="creative-news-slides owl-carousel owl-theme">
            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Posted On</a></li>
                    <li>DECEMBER 14, 2022</li>
                </ul>
                <h3>
                    <a href="/blog-details-1">How To Get Into Tech</a>
                    <p>Tech is a great career choice if you want stability, good pay, and immense growth potential in
                        uncertain times. The industry is a fast-paced, ever-evolving field and you don’t need a degree
                        or years of experience to break.....</p>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">admin</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Posted On</a></li>
                    <li>DECEMBER 6, 2022</li>
                </ul>
                <h3>
                    <a href="/blog-details-2">9 of the Best UI Design Portfolios</a>
                    <p>In the eyes of future employers and clients, a UI designer is only as good as their portfolio.
                        But what does an impressive UI design portfolio actually look like—and how can you take your..
                    </p>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Admin</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Posted On</a></li>
                    <li> NOVEMBER 28, 2022</li>
                </ul>
                <h3>
                    <a href="/blog-details-3">10 Most Popular Programming Lang</a>
                    <p>A quick Google search has led you to this article, and you can now join in the enlightening
                        debate and tell them interesting facts about “PHP”, all the while ignoring that urgent text ...
                    </p>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Admin</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative News Area -->

<!-- Start Creative Contact Area -->
<div class="uk-creative-contact creative-contact-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Contact Us</div>
            <span>LET’S TALK</span>
            <h2>Want To Work With Our Team?</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-contact-image">
                    <img src="assets/img/home-seven/contact.jpg" alt="image">

                    <div class="info-content">
                        <h3>Our Office</h3>
                        <span>4600 Highway 6 North Suite 378, Houston, Texas 77084</span>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <form class="creative-contact-form">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Name</label>
                            <input type="text" class="uk-input" placeholder="Eugene trask">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Email</label>
                            <input type="email" class="uk-input" placeholder="trask@gmail.com">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Phone</label>
                            <input type="text" class="uk-input" placeholder="+1-541-754-3010">
                        </div>

                        <div class="item uk-margin">
                            <label class="uk-form-label" for="form-stacked-text">Subject</label>
                            <input type="text" class="uk-input" placeholder="Your subject here">
                        </div>
                    </div>

                    <div class="item">
                        <label class="uk-form-label" for="form-stacked-text">Your message</label>
                        <textarea class="uk-textarea" cols="30" rows="4"
                            placeholder="Write your message here..."></textarea>
                    </div>

                    <div class="checkbox-boxes">
                        <label><input class="uk-checkbox" type="checkbox"> By Checking This, You Agree To Our <a
                                href="#">Terms</a> And <a href="#">Privacy Policy</a></label>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Contact Area -->