<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services</h1>
        <ul>
            <li><a routerLink="/home">Home</a></li>
            <li>Services</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<!-- <section class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Interview Preparation for job</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/interview-pre-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Sales and Marketing</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/sale-marketing" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>Assistance for getting job</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/job-assistance" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>Software Development</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/software-developer" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services active">
                    <div class="icon">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>UX & UI Design</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/ui-ux-design" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-camera"></i>
                    </div>
                    <h3>Web Development</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/web-development" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>Management</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-chat"></i>
                    </div>
                    <h3>Social Media</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Data Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Digital Branding</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Creative Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>Marketing Solutions</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>Business Analytics</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>UX & UI Design</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-camera"></i>
                    </div>
                    <h3>Photography</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>Management</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-chat"></i>
                    </div>
                    <h3>Social Media</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Data Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a routerLink="/service-details" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Services Area -->

<section class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="section-title-with-big-text top-zero">
            <span>Best Services for Your Business</span>
            <p>
                We handle the sourcing, screening, paperwork, initial interviews
                and background screening services and present you with only well
                qualified candidates.
            </p>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>01</span>
                        </div>
                        <h3>
                            <a routerLink="/interview-pre">Interview Preparation for Job</a>
                        </h3>
                        <p>
                            How to Prepare for a Job Interview So You’ll
                            Convince Them You’re the Right Hire. Here you can
                            work on in your practice interviews.
                        </p>
                        <a class="read-more-btn" routerLink="/interview-pre">
                            Read More <i class="flaticon-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>02</span>
                        </div>
                        <h3>
                            <a  routerLink="/sale-marketing">Sales and Marketing</a>
                        </h3>
                        <p>
                            Sales and marketing are two business functions
                            within an organization -- they both impact lead
                            generation and revenue.
                        </p>
                        <a class="read-more-btn" routerLink="/sale-marketing">
                            Read More <i class="flaticon-right"></i>
                        </a>                       
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>03</span>
                        </div>
                        <h3>
                            <a routerLink="/job-assistance">Assistance for getting job</a>
                        </h3>
                        <p>
                            100% Job Assistance. Assessing the Various Job
                            Options Open to You. Get Professional, Personalized
                            Job Guidance.
                        </p>
                        <a class="read-more-btn" routerLink="/job-assistance">
                            Read More <i class="flaticon-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>04</span>
                        </div>
                        <h3>
                            <a routerLink="/software-developer">Software Development</a>
                        </h3>
                        <p>
                            We offer custom software development services
                            specifically designed to meet business goals at
                            reduced costs.
                        </p>
                        <a class="read-more-btn" routerLink="/software-developer">
                            Read More <i class="flaticon-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>05</span>
                        </div>
                        <h3>
                            <a  routerLink="/ui-ux-design">UX/UI Design & Ideation</a>
                        </h3>
                        <p>
                            Engross more customers by leveraging a well-defined
                            design thinking approach and UI/UX strategies
                            helpful...
                        </p>
                        <a class="read-more-btn" routerLink="/ui-ux-design">
                            Read More <i class="flaticon-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>06</span>
                        </div>
                        <h3>
                            <a routerLink="/web-development">Web Development</a>
                        </h3>
                        <p>
                            We offer a variety of web-based software development
                            services from creating mobile web development
                            solutions...
                        </p>
                        <a class="read-more-btn" routerLink="/web-development">
                            Read More <i class="flaticon-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="uk-services services-section uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>What we do</span>
            <h2>We are modern & special for designing</h2>
            <div class="bar"></div>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="item">
                <div class="services-box">
                    <img src="assets/img/services-img1.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <i class="flaticon-plan"></i>
                        </div>
                        <h3>Digital Branding</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <i class="flaticon-plan"></i>
                                    </div>
                                    <h3>Digital Branding</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="services-box">
                    <img src="assets/img/services-img2.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <i class="flaticon-think"></i>
                        </div>
                        <h3>Creative Solutions</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <i class="flaticon-think"></i>
                                    </div>
                                    <h3>Creative Solutions</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="services-box">
                    <img src="assets/img/services-img3.jpg" alt="image">

                    <div class="content">
                        <div class="icon">
                            <i class="flaticon-shout"></i>
                        </div>
                        <h3>Marketing Solutions</h3>
                    </div>

                    <div class="hover-content">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="inner">
                                    <div class="icon">
                                        <i class="flaticon-shout"></i>
                                    </div>
                                    <h3>Marketing Solutions</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <a routerLink="/service-details" class="details-btn"><i class="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>Subscribe to our newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="name@gmail.com" name="EMAIL" required
                        autocomplete="off" />

                    <button type="submit" class="uk-button uk-button-default">
                        Subscribe Now
                    </button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape">
        <img src="assets/img/footer-shape1.png" alt="shape" />
    </div>
</section>
<!-- End Subscribe Area -->