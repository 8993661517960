<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Contact</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Contact</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Let's Talk</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <!-- <div class="map-img">
                    <img src="assets/img/map.png" alt="map">

                    <div class="location uk-location1">
                        <a href="#" class="active">
                            <div class="location-info">
                                <h5>New York</h5>
                                <span>198 Collective Street</span>
                            </div>
                        </a>
                    </div>

                    <div class="location uk-location2">
                        <a href="#">
                            <div class="location-info">
                                <h5>London</h5>
                                <span>357/71 Collective Street</span>
                            </div>
                        </a>
                    </div>
                </div> -->
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3460.7802748389104!2d-95.64770802445399!3d29.841763475026287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640da1d4dff9f3d%3A0xad582d595e0155e7!2s4600%20Hwy%206%20N%20Suite%20378%2C%20Houston%2C%20TX%2077084%2C%20USA!5e0!3m2!1sen!2sin!4v1721403042676!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="fullscreen; picture-in-picture" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div class="item">
                <form id="contactForm">
                    <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="name" id="name" placeholder="Name">
                        </div>

                        <div class="item uk-margin">
                            <input type="email" class="uk-input" name="email" id="email" placeholder="Email">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" placeholder="Phone">
                        </div>

                        <div class="item uk-margin">
                            <input type="text" class="uk-input" name="subject" id="subject" placeholder="Subject">
                        </div>
                    </div>

                    <div class="item">
                        <textarea name="message" class="uk-textarea" id="message" cols="30" rows="4" placeholder="Your Message"></textarea>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">Submit Message</button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
