<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>SERVICES DETAILS</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Assistance for getting job</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/services/job-assistance.jpg" alt="image" />
            </div>
        </div>

        <div class="project-details-desc">
            <h3>Assistance for getting job</h3>
            <ul>
                 <li style="margin-bottom: 10px;">
                    Assessing the candidate's skills, experience, and career goals to identify job opportunities that
                    are a good fit.
                </li>
                 <li style="margin-bottom: 10px;">
                    Providing guidance on how to create a compelling resume and cover letter that will attract the
                    attention of potential employers.
                </li>
                 <li style="margin-bottom: 10px;">
                    Offering advice on how to prepare for job interviews, including tips on what to expect and how to
                    answer common questions.
                </li>
                 <li style="margin-bottom: 10px;">
                    Connecting the candidate with potential employers and helping them network to increase their chances
                    of finding job opportunities.
                </li>
                 <li style="margin-bottom: 10px;">
                    Providing access to job training and education programs to help the candidate acquire the skills and
                    knowledge needed to succeed in their chosen field.
                </li>
                 <li style="margin-bottom: 10px;">
                    Supporting and guiding the candidate throughout the job search process, providing advice and
                    assistance as needed..
                </li>
            </ul>

            <blockquote class="blockquote">
                <p>Continually assessing the candidate's progress and providing feedback and support to help them succeed in their job search.
                </p>
            </blockquote>

        </div>
    </div>
</section>
<!-- End Services Details Area -->