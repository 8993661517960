import { Component } from '@angular/core';

@Component({
  selector: 'app-blog5',
  standalone: true,
  imports: [],
  templateUrl: './blog5.component.html',
  styleUrl: './blog5.component.scss'
})
export class Blog5Component {

}
