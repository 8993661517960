<!-- Start Black Banner Area -->
<div class="uk-black-banner black-main-banner-area">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="uk-item uk-flex-middle">
                <div class="black-banner-content">
                    <span>Marketing</span>
                    <h1>Start Marketing Journey Today</h1>
                    <p>Pellentesque in ipsum id orci porta dapibus. vivamus suscipit tortor eget felis porttitor volutpat. cras ultricies ligula sed magna dictum porta.</p>

                    <div class="banner-btn">
                        <a href="#" class="uk-button uk-button-default">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="black-banner-image">
                    <img src="assets/img/home-eight/banner/main.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Black Banner Area -->

<!-- Start Featured Boxes Services Area -->
<section class="uk-featured-boxes-services featured-boxes-services-area pt-100 pb-70">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="featured-boxes-services-card">
                    <div class="icon">
                        <i class="flaticon-shout"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Email Marketing</a>
                    </h3>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis lorem ipsum dolor consectetur adipiscing elit.</p>
                </div>
            </div>

            <div class="uk-item">
                <div class="featured-boxes-services-card">
                    <div class="icon bg-two">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Market Analysis</a>
                    </h3>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis lorem ipsum dolor consectetur adipiscing elit.</p>
                </div>
            </div>

            <div class="uk-item">
                <div class="featured-boxes-services-card">
                    <div class="icon bg-three">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>
                        <a href="single-services.html">Keyword Research</a>
                    </h3>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis lorem ipsum dolor consectetur adipiscing elit.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Featured Boxes Services Area -->

<!-- Start Marketing About Area -->
<div class="uk-creative-about marketing-about-area pb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item uk-flex-middle">
                <div class="marketing-about-content">
                    <div class="big-text">About Us</div>
                    <span>About Us</span>
                    <h3>We Are Marketing Expert You Need For Your Business</h3>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents. We work with you, not for you. Although we have a great resources.</p>
                    <h4>Best Marketing Agency In The World</h4>
                    <p>We provide marketing services to startups and small businesses to looking for a partner of their digital media, design & development, lead generation and communications requirents.</p>
                    <div class="about-btn">
                        <a href="#" class="uk-button uk-button-default">Read More</a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="marketing-about-image">
                    <img src="assets/img/home-eight/about.png" alt="image">

                    <div class="content">
                        <h4>15</h4>
                        <b>Years Experience</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Marketing About Area -->

<!-- Start Creative Partner Area -->
<div class="uk-creative-partner creative-partner-area with-bg-color ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Clients</div>
            <span>Valuable Clients</span>
            <h2>See Our Industry Valuable Clients</h2>
        </div>

        <div class="creative-partner-slides owl-carousel owl-theme">
            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner5.png" alt="image">
                </a>
            </div>

            <div class="creative-partner-item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Creative Partner Area -->

<!-- Start Creative Process Area -->
<section class="uk-creative-process creative-process-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">PROCESS</div>
            <span>PROCESS</span>
            <h2>Easy Ways To Get Ready Your Work</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-process-card">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>
                        <a href="#">Project Research</a>
                    </h3>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis lorem ipsum dolor consectetur adipiscing elit.</p>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-process-card">
                    <div class="icon bg-two">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>
                        <a href="#">Project Design</a>
                    </h3>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis lorem ipsum dolor consectetur adipiscing elit.</p>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-process-card">
                    <div class="icon bg-three">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>
                        <a href="#">Project Launch</a>
                    </h3>
                    <p>Risus commodo maecenas accumsan lacus vel facilisis lorem ipsum dolor consectetur adipiscing elit.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative Process Area -->

<!-- Start Creative Video Area -->
<section class="uk-creative-video creative-video-area">
    <div class="uk-container">
        <div class="creative-video-image">
            <img src="assets/img/home-eight/video.jpg" alt="image">
            <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class='bx bx-play'></i></a>

            <div class="video-dot-shape">
                <img src="assets/img/home-eight/dot-shape.png" alt="image">
            </div>
        </div>
    </div>
</section>
<!-- End Creative Video Area -->

<!-- Start Creative Services Area -->
<div class="uk-creative-services creative-services-area pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">What We Do</div>
            <span>OUR SERVICES</span>
            <h2>All The Services That We Provide To Meet The Business Needs Of The Clients</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number">
                            <span>01</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Marketing Solutions</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number two">
                            <span>02</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Mobile Applications</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number three">
                            <span>03</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Web Development</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number four">
                            <span>04</span>
                        </div>
                        <h3>
                            <a href="single-services.html">E-commerce</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number five">
                            <span>05</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Business Analytics</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-services-box">
                    <div class="content">
                        <div class="number six">
                            <span>06</span>
                        </div>
                        <h3>
                            <a href="single-services.html">Creative Solutions</a>
                        </h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat sed porttitor lectus nibh.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Services Area -->

<!-- Start Creative Projects Area -->
<div class="uk-creative-projects creative-projects-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Projects</div>
            <span>OUR COMPLETED PROJECTS</span>
            <h2>Take A Look At All The Projects That We Have Completed For The Client</h2>
        </div>
    </div>
    
    <div class="uk-container-expand">
        <div class="creative-projects-slides-two owl-carousel owl-theme">
            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects1.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Marketing</span>
                    <h3>
                        <a href="single-project.html">Affiliate Marketing</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects2.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Mobile App</span>
                    <h3>
                        <a href="single-project.html">Mobile Applications</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects3.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Hosting</span>
                    <h3>
                        <a href="single-project.html">Web Hosting</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects4.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Development</span>
                    <h3>
                        <a href="single-project.html">Web Development</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>

            <div class="creative-single-projects">
                <div class="projects-image">
                    <a href="single-project.html">
                        <img src="assets/img/home-seven/projects/projects5.jpg" alt="image">
                    </a>
                </div>
                <div class="projects-content">
                    <span>Bridge</span>
                    <h3>
                        <a href="single-project.html">E-commerce</a>
                    </h3>
                </div>
                <div class="projects-icon">
                    <a href="single-project.html"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Projects Area -->

<!-- Start Creative Reviews Area -->
<div class="uk-creative-reviews creative-reviews-area pb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Reviews</div>
            <span>Testimonials</span>
            <h2>What Client Says About Us</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-reviews-img">
                    <img src="assets/img/home-eight/reviews.jpg" alt="image">

                    <div class="icon">
                        <i class="flaticon-quote"></i>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-reviews-slides owl-carousel owl-theme">
                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>

                    <div class="creative-reviews-card">
                        <ul class="rating">
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                            <li><i class='bx bxs-star'></i></li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="client">
                            <h3>Jason Statham</h3>
                            <span>Founder at Envato</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative Reviews Area -->

<!-- Start Creative Pricing Area -->
<section class="uk-creative-pricing creative-pricing-area with-bg-color pt-100 pb-70">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Prices</div>
            <span>OUR PRICES</span>
            <h2>Our Flexible Pricing Plan</h2>
        </div>

        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Starter Plan</h3>
                    </div>

                    <div class="price">
                        $49.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Advance Plan</h3>
                    </div>

                    <div class="price">
                        $59.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>

            <div class="uk-item">
                <div class="creative-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $99.99 <span>Per Month</span>
                    </div>

                    <ul class="features">
                        <li><i class='bx bx-check'></i> 15GB Bandwidth Internet</li>
                        <li><i class='bx bx-check'></i> Business & Financ Analysing</li>
                        <li><i class='bx bx-check'></i> 30 Social Media Reviews</li>
                        <li><i class='bx bx-check'></i> Customer Managemet</li>
                        <li><i class='bx bx-check'></i> Weekly Reports</li>
                        <li><i class='bx bx-check'></i> 24/7 Support</li>
                    </ul>

                    <a href="#" class="uk-button uk-button-default">Choose Plan</a>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="creative-pricing-shape-1">
        <img src="assets/img/home-eight/dot-shape-2.png" alt="image">
    </div>
    <div class="creative-pricing-shape-2">
        <img src="assets/img/home-eight/dot-shape-3.png" alt="image">
    </div>
</section>
<!-- End Creative Pricing Area -->

<!-- Start Creative FAQ Area -->
<div class="uk-creative-faq creative-faq-area ptb-100">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="creative-faq-image">
                    <img src="assets/img/home-eight/faq.png" alt="image">

                    <div class="content">
                        <h4>TOP</h4>
                        <b>Popular Questions</b>
                    </div>
                </div>
            </div>

            <div class="item uk-flex-middle">
                <div class="creative-faq-content">
                    <div class="big-text">FAQ</div>
                    <span>FAQ</span>
                    <h3>Frequently Ask Questions</h3>

                    <div class="creative-faq-accordion">
                        <ul uk-accordion>
                            <li class="uk-open">
                                <a class="uk-accordion-title" href="#">What is the marketing strategy?</a>
                                <div class="uk-accordion-content">
                                    <p>Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor risus. </p>
                                </div>
                            </li>
                            <li>
                                <a class="uk-accordion-title" href="#">What are the best ways to marketing?</a>
                                <div class="uk-accordion-content">
                                    <p>Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor risus. </p>
                                </div>
                            </li>
                            <li>
                                <a class="uk-accordion-title" href="#">What are the benefits of digital marketing?</a>
                                <div class="uk-accordion-content">
                                    <p>Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor risus. </p>
                                </div>
                            </li>
                            <li>
                                <a class="uk-accordion-title" href="#">How do you find a marketing agency?</a>
                                <div class="uk-accordion-content">
                                    <p>Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor risus. </p>
                                </div>
                            </li>
                            <li>
                                <a class="uk-accordion-title" href="#">What is the main purpose of marketing?</a>
                                <div class="uk-accordion-content">
                                    <p>Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor risus. </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Creative FAQ Area -->

<!-- Start Creative News Area -->
<section class="uk-creative-news creative-news-area without-bg-color pb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">News</div>
            <span>Latest News</span>
            <h2>Read Our Latest News</h2>
        </div>

        <div class="creative-news-slides owl-carousel owl-theme">
            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Web</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Burnett</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Development</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Jimmie</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Design</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Rodriguez</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Web</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Burnett</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Development</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Jimmie</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Design</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Rodriguez</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Web</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">University Admissions Could Face Emergency Controls</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client1.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Burnett</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Development</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">How To Create A ReactJS Image Lightbox Gallery?</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client2.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Jimmie</a></h4>
                    </div>
                </div>
            </div>

            <div class="creative-news-card">
                <ul class="meta">
                    <li><a href="#">Design</a></li>
                    <li>02-02-2024</li>
                </ul>
                <h3>
                    <a href="single-blog.html">Hide WooCommerce Products From Specific Categories</a>
                </h3>
                <div class="info">
                    <img src="assets/img/client3.png" alt="image">

                    <div class="title">
                        <h4>By <a href="#">Rodriguez</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Creative News Area -->