<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>SERVICES DETAILS</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>UX/UI Design & Ideation</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/services/ui-ux-design.jpg" alt="image" />
            </div>
        </div>

        <div class="project-details-desc">
            <h3>UX/UI Design & Ideation</h3>
            <ul>
                 <li style="margin-bottom: 10px;">
                    User research and analysis: This involves conducting research to understand the needs and goals of users, and using that information to inform the design of the product. This may include usability testing, user interviews, and surveys..
                </li>
                 <li style="margin-bottom: 10px;">
                    Wireframing and prototyping: This involves creating rough sketches or digital mockups of the product to help visualize and test ideas. This may include creating wireframes, which are low-fidelity representations of the product, or prototypes, which are more interactive and realistic representations.
                </li>
                 <li style="margin-bottom: 10px;">
                    User interface design: This involves creating the visual design of the product, including the layout, color scheme, typography, and other design elements.
                </li>
                 <li style="margin-bottom: 10px;">
                    User experience design: This involves designing the overall user experience of the product, including the flow and functionality of the product, and ensuring that it is intuitive and enjoyable for users.
                </li>
                 <li style="margin-bottom: 10px;">
                    Testing and validation: This involves conducting user testing to ensure that the product meets the needs and goals of users, and making any necessary changes based on feedback.
                </li>
        
                
            </ul>

            <blockquote class="blockquote">
                <p>Documentation and presentation: This may involve creating documentation and presentation materials to communicate the design process and decisions to stakeholders and clients.
                </p>
            </blockquote>

        </div>
    </div>
</section>
<!-- End Services Details Area -->