<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>SERVICES DETAILS</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Software Development</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/services/software-development.jpg" alt="image" />
            </div>
        </div>

        <div class="project-details-desc">
            <h3>Software Development</h3>
            <ul>
                 <li style="margin-bottom: 10px;">
                    Software development: This involves creating software applications to meet specific business needs or requirements. This may involve designing and building a new application from scratch, or modifying and extending an existing application
                </li>
                 <li style="margin-bottom: 10px;">
                    Web development: This involves the development of websites and web-based applications. This may include the design and layout of the site, as well as the development of the underlying functionality.
                </li>
                 <li style="margin-bottom: 10px;">
                    Mobile app development: This involves the development of apps for smartphones and tablets. This may include the design and development of both native apps (built specifically for a particular platform) and cross-platform apps (built to run on multiple platforms).
                </li>
                
            </ul>

            <blockquote class="blockquote">
                <p>Maintenance and support: Software development companies may also provide ongoing maintenance and support for software applications to ensure that they remain up-to-date and functioning properly. This may include bug fixes, security updates, and the addition of new features
                </p>
            </blockquote>

        </div>
    </div>
</section>
<!-- End Services Details Area -->