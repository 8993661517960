<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Blog Details</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Blog</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area uk-blog-details uk-section">
    <div class="uk-container">
        <article class="uk-article blog-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="article-img">
                        <img src="assets/img/blog-details-1.jpg" alt="blog-details">
                        <div class="date">14 Dec 2022</div>
                    </div>

                    <div class="article-content">
                        <ul class="category">
                            <li><a routerLink="/">Posted On : DECEMBER 14, 2022</a></li>
                        </ul>

                        <h3>How To Get Into Tech (With Little to No Experience)</h3>

                        <p>So, you’re thinking about making a career switch to technology but aren’t sure how to get into tech with little to no experience? Don’t worry—it’s definitely possible!</p>

                        <p>With the right attitude, know-how, and resources, you can start your tech journey with confidence and ease.</p>

                        <p>Tech is a great career choice if you want stability, good pay, and immense growth potential in uncertain times. The industry is a fast-paced, ever-evolving field and you don’t need a degree or years of experience to break into it.</p>

                        <!-- <blockquote class="blockquote">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                        </blockquote> -->

                        <p>And what’s even more encouraging to know in economically challenging times is that it counts as a relatively recession-proof sector to enter.</p>
                        <p>To help you figure out how to break into tech with minimal experience, we’ve compiled 11 practical tips for you to consider. We’ll cover:</p>

                        <h3 style="margin-top: 20px;">11 tips on how to get into tech</h3>
                        <ul>
                            <li>Develop relevant tech skills</li>
                            <li>Follow tech experts on social media</li>
                            <li>Use the power of networking</li>
                            <li>Shadow someone in the industry</li>
                            <li>Volunteer for a tech company</li>
                            <li>Get involved in open-source projects</li>
                            <li>Subscribe to industry newsletters</li>
                            <li>Start your own tech blog or podcast</li>
                            <li>Get experience with freelance projects</li>
                            <li>Build a strong portfolio</li>
                            <li>Invest in an online course</li>
                        </ul>
                    </div>
<!-- 
                    <div class="post-controls-buttons uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                        <div class="item">
                            <a routerLink="/blog-details" class="uk-button uk-button-default">Prev Post</a>
                        </div>

                        <div class="item uk-text-right">
                            <a routerLink="/blog-details" class="uk-button uk-button-default">Next Post</a>
                        </div>
                    </div> -->

                    <div id="comments" class="comments-area">
                        <!-- <h2 class="comments-title">3 Comments</h2> -->

                        <!-- <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client1.png" alt="image" class="avatar">
                                            <b class="fn">John Smith</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                March 28, 2024 at 7:16 am
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/client2.png" alt="image" class="avatar">
                                                    <b class="fn">John Smith</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        March 28, 2024 at 7:16 am
                                                    </a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            </div>

                                            <div class="reply">
                                                <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client3.png" alt="image" class="avatar">
                                            <b class="fn">John Smith</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                March 28, 2024 at 7:16 am
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                            </li>
                        </ol> -->

                        <div id="respond" class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form id="commentform" class="comment-form">
                                <p class="comment-notes">Your email address will not be published.</p>

                                <p class="comment-form-comment">
                                    <textarea id="comment" placeholder="Comment" cols="45" rows="5"></textarea>
                                </p>
                                <p class="comment-form-author">
                                    <input id="author" placeholder="Name" type="text">
                                </p>
                                <p class="comment-form-email">
                                    <input id="email" placeholder="Email"  type="text">
                                </p>
                                <p class="comment-form-url">
                                    <input id="url" placeholder="Website" type="text">
                                </p>
                                <p class="form-submit">
                                    <input name="submit" type="submit" id="submit" class="submit" value="Post Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="#">AJAX</a></li>
                            <li><a href="#">Apache</a></li>
                            <li><a href="#">CSS</a></li>
                            <li><a href="#">PHP</a></li>
                            <li><a href="#">Django</a></li>
                            <li><a href="#">Error</a></li>
                            <li><a href="#">IIS</a></li>
                            <li><a href="#">JavaScript</a></li>
                        </ul>
                    </div> -->

                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>
                        <div class="bar"></div>

                        <ul>
                            <li>
                                <a routerLink="/blog-details-1">
                                    <img src="assets/img/blog1.jpg" alt="image">
                                </a>

                                <h5><a routerLink="/blog-details-1">How To Get Into Tech (With Little to No Experience)</a></h5>
                                <p class="date">DECEMBER 14, 2022</p>
                            </li>

                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog2.jpg" alt="image">
                                </a>

                                <h5><a routerLink="/blog-details">9 of the Best UI Design Portfolios That Will Inspire You</a></h5>
                                <p class="date">DECEMBER 6, 2022</p>
                            </li>

                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog3.jpg" alt="image">
                                </a>

                                <h5><a routerLink="/blog-details">A Beginner’s Guide to the 10 Most Popular Programming Languages</a></h5>
                                <p class="date">NOVEMBER 28, 2022</p>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="bar"></div>

                        <div class="tagcloud">
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                        </div>
                    </div> -->

                    <!-- <div class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="#">December 2024</a></li>
                            <li><a href="#">January 2024</a></li>
                            <li><a href="#">February 2024</a></li>
                            <li><a href="#">March 2024</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Blog Details Area -->