<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Blog</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Blog</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area uk-blog uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details-1">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date"> September 15, 2020</span>
                    <h3><a routerLink="/blog-details-1">How To Get Into Tech</a></h3>
                    <p>Tech is a great career choice if you want stability, good pay, and immense growth potential in uncertain times. The industry is a fast-paced, ever-evolving field and you don’t need a degree or years of experience to break.....</p>
                    <a routerLink="/blog-details-1" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details-2">
                        <img src="assets/img/blog2.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">September 17, 2020</span>
                    <h3><a routerLink="/blog-details-2">9 of the Best UI Design Portfolios</a></h3>
                    <p>In the eyes of future employers and clients, a UI designer is only as good as their portfolio. But what does an impressive UI design portfolio actually look like—and how can you take your portfolio to the next level?</p>
                    <a routerLink="/blog-details-2" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details-3">
                        <img src="assets/img/blog3.jpg" alt="image">
                    </a>
                </div>
                <div class="blog-post-content">
                    <span class="date"> NOVEMBER 28, 2022</span>
                    <h3><a routerLink="/blog-details-3">10 Most Popular Programming Lang</a></h3>
                    <p>A quick Google search has led you to this article, and you can now join in the enlightening debate and tell them interesting facts about “PHP”, all the while ignoring that urgent text message from your boss asking....</p>
                    <a routerLink="/blog-details-3" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details-4">
                        <img src="assets/img/blog7.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">DECEMBER 7, 2022</span>
                    <h3><a routerLink="/blog-details-4">A Guide to Entry-Level Tech Jobs</a></h3>
                    <p>For many career changers, the fear of starting from scratch in a junior position is high on the list of concerns. It doesn’t help that the term “entry-level” is often associated with other, more negative terms like...</p>
                    <a routerLink="/blog-details-4" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details-5">
                        <img src="assets/img/blog8.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date">NOVEMBER 29, 2022</span>
                    <h3><a routerLink="/blog-details-5">Which Tech Career Path Is Right</a></h3>
                    <p>Should you become a web developer or a data analyst? Perhaps UI or UX design would be a better fit? How about the ever-growing fields of digital marketing and product management?....</p>
                    <a routerLink="/blog-details-5" class="read-more">Read More</a>
                </div>
            </div>

            <div class="single-blog-post">
                <div class="blog-post-image">
                    <a routerLink="/blog-details-6">
                        <img src="assets/img/blog9.jpg" alt="image">
                    </a>
                </div>

                <div class="blog-post-content">
                    <span class="date"> NOVEMBER 28, 2022</span>
                    <h3><a routerLink="/blog-details-6">11 Top Coding Jobs in 2023</a></h3>
                    <p>If you’re considering a transition into the field, you might be wondering what kind of jobs are out there. For example, did you know coding is used in other jobs besides programming websites and mobile apps....</p>
                    <a routerLink="/blog-details-6" class="read-more">Read More</a>
                </div>
            </div>      
          
        </div>

        <!-- <div class="pagination-area">
            <ul class="uk-pagination uk-flex-center">
                <li><a href="#"><span class="flaticon-back"></span></a></li>
                <li><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li class="uk-active"><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#"><span class="flaticon-right"></span></a></li>
            </ul>
        </div> -->
    </div>
</section>
<!-- End Blog Area -->