import { Component } from '@angular/core';

@Component({
  selector: 'app-blog1',
  standalone: true,
  imports: [],
  templateUrl: './blog1.component.html',
  styleUrl: './blog1.component.scss'
})
export class Blog1Component {

}
