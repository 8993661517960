<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Terms & Conditions</h1>
        <ul>
            <li><a routerLink="/home">Home</a></li>
            <li>Terms & Conditions of CodeRyze</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="project-details-desc">
            <h3>Terms & Conditions of CodeRyze</h3>
            <p>This document is an electronic record in terms of Information Technology Act, and rules there under as applicable and the amended provisions pertaining to electronic records in various Statutes as amended by the Information Technology Act, envis electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
            <p>These terms and conditions ("Terms") govern the use of CodeRyze IT consultancy services and Development Services. By accessing or using our Services, you agree to be bound by these terms. If you do not agree to these Terms, you are not authorized to use our Services.</p>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Definitions :</span>
                <ul style="margin-top: 0;">
                    <li>"Client": refers to the person or entity that engages CodeRyze to provide the Services</li>
                    <li>"Consultant": refers to the person or entity providing the Services on behalf of CodeRyze</li>
                    <li>"Services": refers to the IT consultancy services and Development Services provided by CodeRyze, including but not limited to consulting, development, support, and maintenance.</li>
                </ul>
            </div>
            <div>
                <span style="font-weight: 600;">Scope of Services :</span>
                <p>The scope of the Services will be agreed upon in writing by the Client and CodeRyze prior to the commencement of the Services. The Services will be provided in accordance with this agreed-upon scope.</p>
            </div>

            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Fees and Payment :</span>
                <p>The fees for the Services will be agreed upon in writing by the Client and CodeRyze prior to the commencement of the Services. The Client will pay the fees in accordance with the payment terms agreed upon.</p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Confidentiality :</span>
                <p>
                    CodeRyze and the Consultant will maintain the confidentiality of the Clients information and will not disclose any confidential information to third parties without the prior written consent of the Client.</p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Intellectual Property :</span>
                <p>
                    All intellectual property rights in the Services and any deliverables produced by CodeRyze or the Consultant in the course of providing the Services will be owned by the Client.
                </p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Warranty and liability :</span>
                <p>
                    CodeRyze and the Consultant warrant that the Services will be provided with reasonable care and skill. CodeRyze and the Consultant will not be liable for any indirect or consequential damages arising from the provision of the Services.
                </p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Termination :</span>
                <p>
                    Either party may terminate the agreement for the provision of the Services by giving written notice to the other party. Upon termination, the Client will pay for any Services provided up to the date of termination.
                </p>
            </div>
            
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Governing law :</span>
                <p>
                    These Terms will be governed by and construed in accordance with the laws of jurisdiction.
                </p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Severability :</span>
                <p>
                    If any provision of these Terms is found to be invalid or unenforceable, that provision will be enforced to the maximum extent possible, and the remaining provisions will remain in full force and effect.
                </p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Entire agreement :</span>
                <p>
                    These Terms constitute the entire agreement between the Client and CodeRyze and supersede all prior agreements or understandings, whether written or oral.
                </p>
            </div>
            <div style="margin-top: 15px;">
                <span style="font-weight: 600;">Contact Us :</span>
                <p>If you have any questions or concerns about these Terms, please  <span (click)="redirectToContactPage()"  style="text-decoration: underline;cursor: pointer;color: #ff4800;">contact us.</span>  
                </p>
            </div>                      
        </div>
    </div>
</section>
<!-- End Services Details Area -->