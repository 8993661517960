import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-ux-design',
  standalone: true,
  imports: [],
  templateUrl: './ui-ux-design.component.html',
  styleUrl: './ui-ux-design.component.scss'
})
export class UiUxDesignComponent {

}
