import { Component } from '@angular/core';

@Component({
  selector: 'app-software-developer',
  standalone: true,
  imports: [],
  templateUrl: './software-developer.component.html',
  styleUrl: './software-developer.component.scss'
})
export class SoftwareDeveloperComponent {

}
