import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalAgencyComponent } from './components/pages/digital-agency/digital-agency.component';
import { DigitalAgencyTwoComponent } from './components/pages/digital-agency-two/digital-agency-two.component';
import { DigitalAgencyThreeComponent } from './components/pages/digital-agency-three/digital-agency-three.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DigitalAgencyFourComponent } from './components/pages/digital-agency-four/digital-agency-four.component';
import { BusinessStartupsComponent } from './components/pages/business-startups/business-startups.component';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { CorporateAgencyComponent } from './components/pages/corporate-agency/corporate-agency.component';
import { DigitalMarketingComponent } from './components/pages/digital-marketing/digital-marketing.component';
import { BusinessConsultingComponent } from './components/pages/business-consulting/business-consulting.component';
import { CreativeAgencyComponent } from './components/pages/creative-agency/creative-agency.component';
import { InterviewPreDetailsComponent } from './components/pages/services/interview-pre-details/interview-pre-details.component';
import { SaleMarketingComponent } from './components/pages/services/sale-marketing/sale-marketing.component';
import { JobAssistanceComponent } from './components/pages/services/job-assistance/job-assistance.component';
import { SoftwareDeveloperComponent } from './components/pages/services/software-developer/software-developer.component';
import { UiUxDesignComponent } from './components/pages/services/ui-ux-design/ui-ux-design.component';
import { WebDevelopmentComponent } from './components/pages/services/web-development/web-development.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { Blog1Component } from './components/pages/blog/blog1/blog1.component';
import { Blog2Component } from './components/pages/blog/blog2/blog2.component';
import { Blog3Component } from './components/pages/blog/blog3/blog3.component';
import { Blog4Component } from './components/pages/blog/blog4/blog4.component';
import { Blog5Component } from './components/pages/blog/blog5/blog5.component';
import { Blog6Component } from './components/pages/blog/blog6/blog6.component';

const routes: Routes = [
    { path: '', component: DigitalMarketingComponent },
    { path: 'digital-agency-demo-2', component: DigitalAgencyComponent },
    { path: 'digital-agency-demo-3', component: DigitalAgencyThreeComponent },
    { path: 'digital-agency-demo-4', component: DigitalAgencyFourComponent },
    { path: 'business-startups', component: BusinessStartupsComponent },
    { path: 'seo-agency', component: SeoAgencyComponent },
    { path: 'corporate-agency', component: CorporateAgencyComponent },
    { path: 'home', component: DigitalMarketingComponent },
    { path: 'business-consulting', component: BusinessConsultingComponent },
    { path: 'creative-agency', component: CreativeAgencyComponent },
    { path: 'about', component: AboutComponent },
    { path: 'projects', component: ProjectsComponent },
    { path: 'project-details', component: ProjectDetailsComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'service-details', component: ServiceDetailsComponent },
    { path: 'team', component: TeamComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'interview-pre', component: InterviewPreDetailsComponent},
    { path: 'sale-marketing', component: SaleMarketingComponent},
    { path: 'job-assistance', component: JobAssistanceComponent},
    { path: 'software-developer', component:SoftwareDeveloperComponent },
    { path: 'ui-ux-design', component:UiUxDesignComponent },
    { path: 'web-development', component:WebDevelopmentComponent },
    { path: 'privacy-policy', component:PrivacyPolicyComponent},
    { path: 'term-conditions', component:TermsConditionComponent},
    { path: 'blog-details-1', component:Blog1Component},
    { path: 'blog-details-2', component:Blog2Component},
    { path: 'blog-details-3', component:Blog3Component},
    { path: 'blog-details-4', component:Blog4Component},
    { path: 'blog-details-5', component:Blog5Component},
    { path: 'blog-details-6', component:Blog6Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
