<!-- Start Footer Area -->
<footer class="footer-area {{location == '/' ? '' : 'uk-dark'}} uk-footer">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img style="height: 45px;" src="assets/img/logo2.png" alt="logo">
                        </a>
                    </div>
                    <p>CodeRyze laid its roots from basics of employee management, thereby bringing a unique approach to cater the requirements of our Clients.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Quick Link</h3>
                    <div class="bar"></div>

                    <div class="quick-link">
                       <a routerLink="/home">Home Page</a>
                       <a routerLink="/about">About Us </a>
                       <a routerLink="/services">Services</a>
                       <a routerLink="/contact">Contact Us</a>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Our Office</h3>
                    <div class="bar"></div>

                    <div class="location">
                        <p>4600 Highway 6 North Suite 378, Houston, Texas 77084 <br>United States</p>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact Us</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li><a href="mailto:contact&#64;coderyzeinc.com">contact&#64;coderyzeinc.com</a></li>
                        <li><a href="tel:+1 (302) 265-3502">+1 (302) 265-3502</a></li>
                    </ul>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-logo"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/company/coderyze" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-logo-1"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <p>CodeRyze © 2023 All rights reserved. Terms of use and Privacy Policy</p>
                </div>

                <div class="item">
                    <ul>
                        <li><a routerLink="/term-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <div class="br-line"></div>
    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="shape"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="shape"></div>
</footer>
<!-- End Footer Area -->
