<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Blog Details</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Blog</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area uk-blog-details uk-section">
    <div class="uk-container">
        <article class="uk-article blog-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="article-img">
                        <img src="assets/img/blog-details-2.jpg" alt="blog-details">
                        <div class="date">6 Dec 2022</div>
                    </div>

                    <div class="article-content">
                        <ul class="category">
                            <li><a routerLink="/">Posted On:DECEMBER 6, 2022</a></li>
                        </ul>

                        <h3>9 of the Best UI Design Portfolios That Will Inspire You</h3>

                        <p>In the eyes of future employers and clients, a UI designer is only as good as their portfolio. But what does an impressive UI design portfolio actually look like—and how can you take your portfolio to the next level?</p>

                        <p>Whether you’re a UI designer in the making who’s starting from scratch, or an experienced designer looking to breathe new life into your existing portfolio, we’ve rounded up nine gorgeous UI design portfolios that will serve as inspiration. As we go through them, we’ll highlight some best practices and key takeaways that will help you to take your UI design portfolio from “okay” to “eye-catching.”</p>

                        <p>Before we dive in, let’s touch on why UI design portfolios are so vital, and some general notes on what they should include. Rather skip straight to the portfolios? No problem, simply select one from the list below!</p>

                        <!-- <blockquote class="blockquote">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, iste? Aut ipsam consequuntur non rem tenetur dolore consequatur ducimus a labore excepturi quae nisi, quisquam, maxime voluptates magnam aliquid. Cupiditate.</p>
                        </blockquote> -->                    
                        <ul>
                            <li>Sam Small</li>
                            <li>Jeremiah Shaw</li>
                            <li>Mengdi Zhang</li>
                            <li>Bady</li>
                            <li>Tom Parkes</li>
                            <li>Stefan Hiienurm</li>
                            <li>Bradley Haynes</li>
                            <li>Corey Snyder</li>
                            <li>Sinem Kurt</li>
                        </ul>
                    </div>
<!-- 
                    <div class="post-controls-buttons uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                        <div class="item">
                            <a routerLink="/blog-details" class="uk-button uk-button-default">Prev Post</a>
                        </div>

                        <div class="item uk-text-right">
                            <a routerLink="/blog-details" class="uk-button uk-button-default">Next Post</a>
                        </div>
                    </div> -->

                    <div id="comments" class="comments-area">
                        <!-- <h2 class="comments-title">3 Comments</h2> -->

                        <!-- <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client1.png" alt="image" class="avatar">
                                            <b class="fn">John Smith</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                March 28, 2024 at 7:16 am
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/client2.png" alt="image" class="avatar">
                                                    <b class="fn">John Smith</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a href="#">
                                                        March 28, 2024 at 7:16 am
                                                    </a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                            </div>

                                            <div class="reply">
                                                <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client3.png" alt="image" class="avatar">
                                            <b class="fn">John Smith</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a href="#">
                                                March 28, 2024 at 7:16 am
                                            </a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    </div>

                                    <div class="reply">
                                        <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                            </li>
                        </ol> -->

                        <div id="respond" class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form id="commentform" class="comment-form">
                                <p class="comment-notes">Your email address will not be published.</p>

                                <p class="comment-form-comment">
                                    <textarea id="comment" placeholder="Comment" cols="45" rows="5"></textarea>
                                </p>
                                <p class="comment-form-author">
                                    <input id="author" placeholder="Name" type="text">
                                </p>
                                <p class="comment-form-email">
                                    <input id="email" placeholder="Email"  type="text">
                                </p>
                                <p class="comment-form-url">
                                    <input id="url" placeholder="Website" type="text">
                                </p>
                                <p class="form-submit">
                                    <input name="submit" type="submit" id="submit" class="submit" value="Post Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="#">AJAX</a></li>
                            <li><a href="#">Apache</a></li>
                            <li><a href="#">CSS</a></li>
                            <li><a href="#">PHP</a></li>
                            <li><a href="#">Django</a></li>
                            <li><a href="#">Error</a></li>
                            <li><a href="#">IIS</a></li>
                            <li><a href="#">JavaScript</a></li>
                        </ul>
                    </div> -->

                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>
                        <div class="bar"></div>

                        <ul>
                            <li>
                                <a routerLink="/blog-details-1">
                                    <img src="assets/img/blog1.jpg" alt="image">
                                </a>

                                <h5><a routerLink="/blog-details-1">How To Get Into Tech (With Little to No Experience)</a></h5>
                                <p class="date">DECEMBER 14, 2022</p>
                            </li>

                            <li>
                                <a routerLink="/blog-details-2">
                                    <img src="assets/img/blog2.jpg" alt="image">
                                </a>

                                <h5><a routerLink="/blog-details-2">9 of the Best UI Design Portfolios That Will Inspire You</a></h5>
                                <p class="date">DECEMBER 6, 2022</p>
                            </li>

                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog3.jpg" alt="image">
                                </a>

                                <h5><a routerLink="/blog-details">A Beginner’s Guide to the 10 Most Popular Programming Languages</a></h5>
                                <p class="date">NOVEMBER 28, 2022</p>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="bar"></div>

                        <div class="tagcloud">
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                        </div>
                    </div> -->

                    <!-- <div class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a href="#">December 2024</a></li>
                            <li><a href="#">January 2024</a></li>
                            <li><a href="#">February 2024</a></li>
                            <li><a href="#">March 2024</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Blog Details Area -->