import { Component } from '@angular/core';

@Component({
  selector: 'app-blog6',
  standalone: true,
  imports: [],
  templateUrl: './blog6.component.html',
  styleUrl: './blog6.component.scss'
})
export class Blog6Component {

}
