import { Component } from '@angular/core';

@Component({
  selector: 'app-job-assistance',
  standalone: true,
  imports: [],
  templateUrl: './job-assistance.component.html',
  styleUrl: './job-assistance.component.scss'
})
export class JobAssistanceComponent {


}
