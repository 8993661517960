import { Component } from '@angular/core';

@Component({
  selector: 'app-interview-pre-details',
  standalone: true,
  imports: [],
  templateUrl: './interview-pre-details.component.html',
  styleUrl: './interview-pre-details.component.scss'
})
export class InterviewPreDetailsComponent {

}
