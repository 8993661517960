import { Component } from '@angular/core';

@Component({
  selector: 'app-blog4',
  standalone: true,
  imports: [],
  templateUrl: './blog4.component.html',
  styleUrl: './blog4.component.scss'
})
export class Blog4Component {

}
