<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>About</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>About</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="uk-about about-area uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Building the Future of Technology</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <!-- <h3>Best Digital Agency in the World</h3> -->
                        <p>CodeRyze Inc. aims to stand at the forefront of technology, delivering tailored solutions and services in key domains, including Web Development, Cloud Platforms, Application Architecture and Software Development.</p>
                        <p>Our expertise extends to mitigating information system risks through Data Quality and regulatory compliance solutions.</p>
                        <p>Additionally, we offer a comprehensive suite of enterprise workforce solutions, encompassing Contingent Workforce management, Full-time / Permanent staffing, and Recruiting Process Outsourcing (RPO) services, catering to a wide spectrum of businesses</p>

                        <!-- <div class="signature">
                            <img src="assets/img/signature.png" alt="signature">
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img src="assets/img/about1.jpg" class="about-img1" alt="about-img">
                    <img src="assets/img/about2.jpg" class="about-img2" alt="about-img">
                    <img src="assets/img/1.png" class="shape-img" alt="shape">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Feedback Area -->
<section id="clients" class="feedback-area uk-section uk-feedback">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <div class="feedback-img">
                    <img src="assets/img/women.jpg" alt="image">

                    <img src="assets/img/1.png" class="shape-img" alt="image">

                    <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-multimedia"></i> Watch Video</a> -->
                </div>
            </div>

            <div class="item">
                <div class="feedback-inner">
                    <div class="uk-section-title section-title">
                        <span>What Client Says About US</span>
                        <h2>Our Testimonials</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>"Reliable and Responsive Service CodeRyze Technologies has been our go- to partner for sourcing software engineers.Their ability to quickly understand our requirements and provide candidates who fit seamlessly into our projects is commendable.Their consultants are not only skilled but also proactive in resolving any issues that arise.We appreciate their flexibility and dedication to meeting our staffing needs efficiently. CodeRyze Technologies has significantly contributed to our project successes, and we look forward to continuing our partnership."</p>

                            <div class="client">
                                <!-- <h3>Google</h3> -->
                                <span>Google</span>
                            </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>"A Game-Changer for Staffing Needs CodeRyze Technologies has been instrumental in our company's growth by consistently delivering exceptional software engineers. Their team's meticulous screening process ensures that every candidate not only meets but exceeds our expectations. Their responsiveness and proactive communication make them a pleasure to work with. Highly recommend CodeRyze Technologies for any company looking to augment their technical team with top-notch talent."</p>

                        <div class="client">
                            <!-- <h3>Jason Statham</h3> -->
                            <span>Nvidia</span>
                        </div>
                        </div>

                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>"Seamless Integration and Exceptional Support CodeRyze Technologies has been instrumental in filling critical software engineering roles within our organization. Their team understands our needs deeply and consistently provides candidates who exceed our technical and cultural expectations. Their consultants are responsive and proactive, ensuring that our projects stay on track. CodeRyze Technologies dedication to quality and their ability to adapt to our evolving requirements make them a trusted partner in our staffing strategy. We're grateful for their contributions to our success and would recommend CodeRyze Technologies without hesitation."</p>

                            <div class="client">
                                <!-- <h3>Jason Statham</h3> -->
                                <span>Lockheed Martin</span>
                            </div>
                        </div>
                        <div class="single-feedback">
                            <i class="flaticon-quote"></i>
                            <p>"Exceptional Engineering Expertise CodeRyze Technologies has consistently delivered outstanding software engineers who have become integral parts of our development teams. Their consultants bring not only technical proficiency but also a collaborative spirit that aligns perfectly with our company culture. Their commitment to quality and their thorough vetting process ensure that we receive candidates who are well-suited to our specific project requirements. Working with Innovative Talent Partners has been a true partnership, and we highly recommend their services to any company seeking top engineering talent."</p>

                            <div class="client">
                                <!-- <h3>Jason Statham</h3> -->
                                <span>Wells Fargo</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner4.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner5.png" alt="image">
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner6.png" alt="image">
                </a>
            </div>
            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner7.png" alt="image">
                </a>
            </div>
            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner8.png" alt="image">
                </a>
            </div>
            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner9.png" alt="image">
                </a>
            </div>
            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner10.png" alt="image">
                </a>
            </div>
            <div class="item">
                <a href="#">
                    <img src="assets/img/home-seven/partner/partner11.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Team Area -->
<!-- <section id="team" class="team-area uk-team uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Meet Our Experts</span>
            <h2>Our Team</h2>
            <div class="bar"></div>

            <a href="#" class="uk-button uk-button-default">View All</a>
        </div>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team2.jpg" alt="image">

            <div class="team-content">
                <h3>David Warner</h3>
                <span>UX/UI Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team3.jpg" alt="image">

            <div class="team-content">
                <h3>Emili Lucy</h3>
                <span>Project Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team4.jpg" alt="image">

            <div class="team-content">
                <h3>Steven Smith</h3>
                <span>Marketing Manager</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team5.jpg" alt="image">

            <div class="team-content">
                <h3>Steve Eva</h3>
                <span>Creative Designer</span>
            </div>
        </div>

        <div class="single-team">
            <ul class="team-social">
                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
            </ul>

            <img src="assets/img/team1.jpg" alt="image">

            <div class="team-content">
                <h3>Josh Buttler</h3>
                <span>Content Writer</span>
            </div>
        </div>
    </div>
</section> -->

<section class="uk-creative-team creative-team-area ptb-100">
    <div class="uk-container">
        <div class="section-title-with-big-text">
            <div class="big-text">Our Team</div>
            <span>MEET OUR EXPERTS</span>
            <h2>Meet Our Experts Team Member</h2>
        </div>
        
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s">
            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image">
                        <img src="assets/img/home-seven/team/team1.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://in.linkedin.com/in/amansingh-java?original_referer=https%3A%2F%2Fwww.google.com%2F" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                    </div>
                    <div class="team-content">
                        <h3>Aman Singh</h3>
                        <span>Java Developer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image style-two">
                        <img src="assets/img/home-seven/team/team2.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://www.linkedin.com/in/-conecthere/" target="_blank"><i
                                        class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Aman Yadav</h3>
                        <span>Delivery Manager </span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image">
                        <img src="assets/img/home-seven/team/team3.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://in.linkedin.com/in/ravi-gautam-9304a8218" target="_blank"><i
                                        class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Ravi Gautam</h3>
                        <span>Front-End Developer</span>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="creative-team-box">
                    <div class="team-image style-two">
                        <img src="assets/img/home-seven/team/team4.jpg" alt="image">

                        <ul class="team-social">
                            <li><a href="https://www.linkedin.com/in/harsh-tomar-connect/" target="_blank"><i
                                        class="flaticon-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div class="team-content">
                        <h3>Harsh Tomar</h3>
                        <span>Sr. Bench Sales Recruiter</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- End Team Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area uk-section uk-subscribe bg-gray">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
            <div class="item">
                <h3>Subscribe to our newsletter</h3>
            </div>

            <div class="item">
                <form class="newsletter-form">
                    <input type="email" class="uk-input" placeholder="name@gmail.com" name="EMAIL" required autocomplete="off">

                    <button type="submit" class="uk-button uk-button-default">Subscribe Now</button>
                </form>
            </div>
        </div>
    </div>

    <div class="shape"><img src="assets/img/footer-shape1.png" alt="shape"></div>
</section>
<!-- End Subscribe Area -->
