<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Project Details</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Project Details</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/project-details.jpg" alt="image">
            </div>

            <div class="item uk-width-1-5">
                <div class="project-details-info">
                    <ul>
                        <li><Span>Customer Name:</Span> Steven John</li>
                        <li><Span>Category:</Span> Art & Design</li>
                        <li><Span>Date:</Span> 04/11/2024</li>
                        <li><Span>Status:</Span> In Process</li>
                        <li><Span>Demo Link:</Span> <a href="#">www.gunter.com</a></li>
                        <li><Span>Tags:</Span> <a href="#">Art</a>, <a href="#">Design</a></li>
                        <li>
                            <Span>Share:</Span>

                            <ul>
                                <li><a href="#"><i class="flaticon-logo"></i></a></li>
                                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i class="flaticon-logo-1"></i></a></li>
                                <li><a href="#"><i class="flaticon-linkedin"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="project-details-desc">
            <h3>Development</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>

            <blockquote class="blockquote">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt Lorem ipsum dolor sit amet.</p>
            </blockquote>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
        </div>

        <div class="project-next-and-prev">
            <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                <div class="item">
                    <a href="#" class="uk-button uk-button-default">Prev Project</a>
                </div>

                <div class="item uk-text-right">
                    <a href="#" class="uk-button uk-button-default">Next Project</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->
