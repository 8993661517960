<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>SERVICES DETAILS</h1>
        <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Web Development</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area --> 

<!-- Start Services Details Area -->
<section class="project-details-area uk-project-details uk-section">
    <div class="uk-container">
        <div class="uk-grid uk-flex project-details">
            <div class="project-details-img uk-width-expand">
                <img src="assets/img/services/web-development.jpg" alt="image" />
            </div>
        </div>

        <div class="project-details-desc">
            <h3>Web Development</h3>
            <ul>
                 <li style="margin-bottom: 10px;">
                    Custom website design and development: This involves creating a unique website from scratch, or customizing an existing website to meet specific business needs or requirements.
                </li>
                 <li style="margin-bottom: 10px;">
                    E-commerce development: This involves building and maintaining online stores and other e-commerce platforms. This may include the development of shopping carts, payment gateways, and other features necessary for online transactions
                </li>
                 <li style="margin-bottom: 10px;">
                    Content management systems (CMS) development: This involves developing or customizing CMS platforms to allow businesses to easily create and manage the content on their websites.
                </li>
                 <li style="margin-bottom: 10px;">
                    Responsive web design: This involves designing websites that are optimized for viewing on a variety of devices, including desktop computers, laptops, tablets, and smartphones.
                </li>
                 <li style="margin-bottom: 10px;">
                    Search engine optimization (SEO): This involves optimizing websites to improve their visibility and ranking in search engine results pages
                </li>       
            </ul>

            <blockquote class="blockquote">
                <p>Website maintenance and support: This may include ongoing support and maintenance to ensure that the website remains up-to-date and functioning properly, as well as the addition of new features or functionality.
                </p>
            </blockquote>
        </div>
    </div>
</section>
<!-- End Services Details Area -->